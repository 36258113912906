(() => {
  'use strict';

  // Const
  // -----
  const MAX_VIEWPORT_WIDTH = 1920;

  window.SMALL_MOBILE_WIDTH = 360;
  window.PORTRAIT_MOBILE_WIDTH = 480;
  window.LARGE_PORTRAIT_MOBILE_WIDTH = 700;
  window.LANDSCAPE_MOBILE_WIDTH = 800;
  window.PORTRAIT_TABLET_WIDTH = 768;
  window.LANDSCAPE_SMALL_TABLET_WIDTH = 900;
  window.LANDSCAPE_TABLET_WIDTH = 980;
  window.DESKTOP_WIDTH = 1024;
  window.DESKTOP_WIDE_WIDTH = 1366;

  // Selectors
  // ---------
  window.$WINDOW = $(window);
  window.$DOCUMENT = $(document);
  window.$HTML = $(document.documentElement);
  window.$BODY = $(document.body);
  window.$HEADER = $('.header');
  window.$HEADER_MENU_WRAPPER = $('.header-info-menu-container');
  window.$HEADER_MENU = $('.header-info-menu > ul');
  window.$MENU_CHECKBOX = $('.header-menu-checkbox');

  // Helpers
  // -------
  $WINDOW
    .on('calcSizes', () => {
      window.VIEWPORT_WIDTH = document.documentElement.clientWidth || Math.round(window.visualViewport.width) || $WINDOW.width();
      window.WINDOW_HEIGHT = $WINDOW.height();
      window.HEADER_HEIGHT = $HEADER_MENU_WRAPPER.height();
    })
    .on('resize', () => {
      $WINDOW.trigger('calcSizes');
    })
    .trigger('calcSizes');

  /**
   * @return boolean
   */
  window.IS_DESKTOP_WIDE_WIDTH = () => VIEWPORT_WIDTH > DESKTOP_WIDE_WIDTH;
  /**
   * @return boolean
   */
  window.IS_DESKTOP_WIDTH = () => VIEWPORT_WIDTH >= DESKTOP_WIDTH;
  /**
   * @return boolean
   */
  window.IS_TABLET_WIDTH = () => (VIEWPORT_WIDTH >= PORTRAIT_TABLET_WIDTH && VIEWPORT_WIDTH < DESKTOP_WIDTH);
  /**
   * @return boolean
   */
  window.IS_WIDTH_LESS_PORTRAIT_TABLET = () => VIEWPORT_WIDTH < PORTRAIT_TABLET_WIDTH;
  /**
   * @return boolean
   */
  window.IS_LANDSCAPE_TABLET_WIDTH = () => VIEWPORT_WIDTH < LANDSCAPE_TABLET_WIDTH;
  /**
   * @return boolean
   */
  window.IS_LANDSCAPE_SMALL_TABLET_WIDTH = () => VIEWPORT_WIDTH < LANDSCAPE_SMALL_TABLET_WIDTH;
  /**
   * @return boolean
   */
  window.IS_MOBILE_WIDTH = () => VIEWPORT_WIDTH < LANDSCAPE_MOBILE_WIDTH;
  /**
   * @return boolean
   */
  window.IS_LARGE_PORTRAIT_MOBILE_WIDTH = () => VIEWPORT_WIDTH < LARGE_PORTRAIT_MOBILE_WIDTH;
  /**
   * @return boolean
   */
  window.IS_PORTRAIT_MOBILE_WIDTH = () => VIEWPORT_WIDTH < PORTRAIT_MOBILE_WIDTH;
  /**
   * @return boolean
   */
  window.IS_SMALL_MOBILE_WIDTH = () => VIEWPORT_WIDTH < SMALL_MOBILE_WIDTH;


  // Masked input
  // ------------
  $('input[type="date"]').attr('type', 'text');

  Inputmask.extendAliases({
    'phone-mask': {
      regex: '(\\+7|8) \\([0-689]\\d{2}\\) \\d{3}(\\-\\d{2}){2}',
      placeholder: '+7 (___) ___-__-__',
      keepStatic: true
    }
  });

  // Phone
  $('input[type="tel"], input[autocomplete="tel"]').inputmask('phone-mask');



  $WINDOW
    .one('load', () => {
      $WINDOW.trigger('calcSizes');
      $DOCUMENT
        .trigger('headerContentPadding')
        .trigger('headerMenuEvents');

    })
    .on('structureScale', () => {
      $('.structure').css('transform', (VIEWPORT_WIDTH > MAX_VIEWPORT_WIDTH ? 'scale('.concat(VIEWPORT_WIDTH / MAX_VIEWPORT_WIDTH, ')') : ''));
    })
    .on('resize', () => {
      $WINDOW.trigger('structureScale');
      $DOCUMENT
        .trigger('headerContentPadding')
        .trigger('headerMenuEvents');
    })
    .trigger('structureScale');


  $DOCUMENT
    .ready(() => {
      // Header menu button
      // ------------------
      $MENU_CHECKBOX
        .on('change', e => {
          e.currentTarget.checked ? $BODY.addClass('menu-is-opened') : $BODY.removeClass('menu-is-opened');
        })
        .prop({'checked': false, 'disabled': false})
        .trigger('change');


      // Lazy images load
      // ----------------
      $('.js-lazy-load').unveil({
        attribute: 'lazySrc',
        placeholder: '',
        offset: 50,
        throttle: 200
      });
      $('.portfolio-block-container .list-view-item-image > span, .portfolio-section-block-container .list-view-item-image > a')
        .unveil({
          attribute: 'lazySrc',
          placeholder: '',
          offset: 50,
          throttle: 200
        })
        .on('loaded.unveil', e => {
          $(e.currentTarget).closest('.list-view-item-wrapper').addClass('unveil-loaded');
        });


      // Services rotator
      // ----------------
      $('.services-rotator-container').each((i, element) => {
        let servicesRotatorBreakpoints = {};
        servicesRotatorBreakpoints[LARGE_PORTRAIT_MOBILE_WIDTH] = {arrows: true};

        let $self = $(element),
            servicesRotator = new Splide($self.find('.services-rotator').get(0), {
              type: 'fade',
              role: undefined,
              rewind: true,
              speed: 400,
              height: 'unset',
              autoplay: true,
              arrows: false,
              pagination: false,
              drag: false,
              easing: 'ease',
              interval: 6000,
              pauseOnHover: true,
              updateOnMove: true,
              direction: 'ttb',
              ignoreReducedMotion: true,
              waitForTransition: true,
              breakpoints: servicesRotatorBreakpoints
            }),
            serviceRotatorPagination = new Splide($self.find('.services-rotator-pagination').get(0), {
              perPage: 5,
              role: undefined,
              rewind: true,
              speed: 400,
              height: 'unset',
              autoHeight: true,
              focus: 'center',
              autoplay: false,
              arrows: true,
              pagination: false,
              drag: false,
              easing: 'ease',
              isNavigation: true,
              pauseOnHover: true,
              updateOnMove: true,
              direction: 'ttb',
              ignoreReducedMotion: true
            })
              .on('ready', () => {
                if (serviceRotatorPagination.length > 5)
                  serviceRotatorPagination.root.classList.add('with-arrows');
              })
              .on('move', (newIndex, prevIndex, destIndex) => {
                let lastIndex = serviceRotatorPagination.length - 1,
                    withArrows = serviceRotatorPagination.length > 5;

                if ((prevIndex === 0 && destIndex === lastIndex && withArrows) ||
                  (prevIndex > destIndex && (!withArrows || prevIndex !== lastIndex || destIndex !== 0)))
                  serviceRotatorPagination.root.classList.add('invert-fix');
                else
                  serviceRotatorPagination.root.classList.remove('invert-fix');
              });

        servicesRotator.sync(serviceRotatorPagination).mount();
        serviceRotatorPagination.mount();
      });
    })

    .on('headerContentPadding', () => {
      $('.header-content').css('padding-top', IS_DESKTOP_WIDTH() ? '' : $('.header-info-menu-container').outerHeight());
    })

    // Header menu events
    // ------------------
    .on('headerMenuEvents', () => {
      if (IS_DESKTOP_WIDTH()) {
        if (!$HEADER_MENU.hasClass('is-desktop-events')) {
          $DOCUMENT
            .off('click.closeHeaderMenu')
            .off('click.headerMenu');

          $MENU_CHECKBOX.prop('checked', false).trigger('change');

          $HEADER_MENU
            .addClass('is-desktop-events')
            .removeClass('is-mobile-events')

            .find('li').removeClass('is-hovered')
            .find('ul').css({'display': '', 'height': ''});

          $HEADER_MENU.children('.service-menu-item').detach().appendTo('.header-service-menu .header-menu > ul');

          // Move contacts
          $('.header-phones').detach().insertBefore('.header-email');
        }
      }
      else {
        if (!$HEADER_MENU.hasClass('is-mobile-events')) {
          let $serviceMenuItems = $('.header-service-menu .header-menu > ul > li').addClass('service-menu-item');

          if ($HEADER_MENU.children('li.service-items-after').length)
            $serviceMenuItems.detach().insertAfter($HEADER_MENU.children('li.service-items-after').first());
          else if ($HEADER_MENU.children('li.service-items-before').length)
            $serviceMenuItems.detach().insertBefore($HEADER_MENU.children('li.service-items-before').first());
          else
            $serviceMenuItems.detach().appendTo($HEADER_MENU);

          $HEADER_MENU
            .addClass('is-mobile-events')
            .removeClass('is-desktop-events')

            .find('li.is-opened').addClass('is-hovered');

          // Move contacts
          $('.header-phones').detach().appendTo('#contacts-popup > .form-block-container > div:first');

          $DOCUMENT
            // Close header menu
            .on('click.closeHeaderMenu', e => {
              if (!($(e.target).closest('.header-info-menu').length || $(e.target).closest('.header-menu-button-container').length)) {
                $MENU_CHECKBOX.prop('checked', false).trigger('change');

                e.stopPropagation();
              }
            })

            // Header menu items
            .on('click.headerMenu', '.header-info-menu > ul a, .header-info-menu > ul div > span, .header-info-menu > ul .dropmarker', e => {
              e.preventDefault();

              let $self = $(e.currentTarget),
                  $parent = $self.closest('li'),
                  isWithDropdown = $parent.hasClass('with-dropdown'),
                  isOnHover = $parent.hasClass('is-hovered');

              if ($self.prop('tagName').toUpperCase() === 'A')
                location.href = $self.prop('href');
              else if (isWithDropdown) {
                if (isOnHover)
                  $parent.removeClass('is-hovered').children('ul').slideUp(200);
                else {
                  $parent.siblings().removeClass('is-hovered').children('ul').slideUp(200);
                  $parent.addClass('is-hovered').children('ul').slideDown(200);
                }
              }
            });
        }
      }
    })

    // Faq list answers visible toggle
    // -------------------------------
    .on('click.faqListAnswer', '.faq-list > div > div:first-child', e => {
      e.preventDefault();

      let $self = $(e.currentTarget).parent();

      if (!$self.hasClass('active'))
        $self.addClass('active').children('div + div').slideDown(200);
      else
        $self.removeClass('active').children('div + div').slideUp(200);
    })

    // Callback order form services hints
    // ----------------------------------
    .on('mouseenter.callbackOrderFormServices mouseleave.callbackOrderFormServices', '#callback-order .form-row-name-services .form-field > div > label > span > span + span', e => {
      if (!IS_WIDTH_LESS_PORTRAIT_TABLET()) {
        let $hint = $('.callback-order-services-hints > div[data-id="'.concat($(e.currentTarget).closest('label').prev('input').val(), '"]'));

        if ($hint.length) {
          if (e.type === 'mouseenter')
            $hint.addClass('active');
          else
            $hint.removeClass('active');
        }
      }
    })
    .on('click.callbackOrderFormServices', '#callback-order .form-row-name-services .form-field > div > label > span > span + span > span + span', e => {
      if (IS_WIDTH_LESS_PORTRAIT_TABLET()) {
        e.preventDefault();

        let $hint = $('.callback-order-services-hints > div[data-id="'.concat($(e.currentTarget).closest('label').prev('input').val(), '"]'));

        if ($hint.length)
          $.fancybox.open('<div class="form-popup-container"><div class="form-block-container callback-order-service-hint-popup"><p class="subheader4">'.concat($(e.currentTarget).prev().text(), '</p>', $hint.prop('outerHTML'), '</div></div>'), {type: 'inline'});
      }
    })

    // Scroll to
    // ---------
    .on('scrollTo', (e, $element, speed, offset) => {
      if ($element === undefined)
        $element = $(e.target);

      if (speed === undefined)
        speed = $element.data('scrollSpeed') || 150;

      if (offset === undefined)
        offset = $element.data('scrollOffset') || 0;

      $WINDOW.scrollTo($element, {axis: 'y', duration: speed, offset: parseInt(offset) - (IS_DESKTOP_WIDTH() ? 0 : HEADER_HEIGHT)});
    })
    .on('click.jsScrollTo', '.js-scroll-to', e => {
      e.preventDefault();

      let $link = $(e.currentTarget),
          $element = $($link.attr('href').substring($link.attr('href').lastIndexOf('#'))),
          speed = $link.data('scrollSpeed') || 150,
          offset = $link.data('scrollOffset') || undefined;

      $DOCUMENT.trigger('scrollTo', [$element, speed, offset]);
    })

    // Video frame load
    // ----------------
    .on('click.videoFrame', '.video-frame > a', e => {
      e.preventDefault();

      let $self = $(e.currentTarget),
          $iframe = $self.next();

      $iframe.attr('src', () => $iframe.data('src'));

      if ($self.data('popup') !== undefined)
        $.fancybox.open($iframe);
      else
        $iframe.parent().addClass('active');
    });


  // FancyBox
  // --------
  $.extend(true, $.fancybox.defaults, {
    autoFocus: false,
    loop: true,
    infobar: false,
    smallBtn: true,
    buttons: [
      false,
      false,
      false,
      'close'
    ],
    idleTime: false,
    transitionEffect: 'slide',
    hash: false,
    mobile: {
      clickSlide: 'close'
    },
    lang: 'ru',
    i18n: {
      ru: {
        CLOSE: 'Закрыть',
        NEXT: 'Следующий слайд',
        PREV: 'Предыдущий слайд',
        ERROR: 'Ошибка загрузки. <br/>Пожалуйста, попробуйте позже.',
        PLAY_START: 'Запустить показ слайдов',
        PLAY_STOP: 'Остановить показ слайдов',
        FULL_SCREEN: 'В полный экран',
        THUMBS: 'Миниатюры',
        DOWNLOAD: 'Скачать',
        SHARE: 'Поделиться',
        ZOOM: 'Масштаб'
      }
    },

    beforeLoad: (widget, current) => {
      switch (current.type) {
        case 'image':
          current.opts.protect = true;
          break;

        case 'inline':
          current.opts.touch = false;
          break;
      }
    },
    afterLoad: (widget, current) => {
      if (current.contentType == 'html' && !IS_MOBILE && !IS_MOBILE_WIDTH())
        current.$content.find('.js-autofocus-inp').trigger('focus');
    }
  });
})();